import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "api-changelog"
    }}>{`API Changelog`}</h1>
    <h3 {...{
      "id": "v140-20210928"
    }}>{`v1.4.0 (`}<strong parentName="h3">{`2021.09.28`}</strong>{`)`}</h3>
    <h4 {...{
      "id": "js-api"
    }}><strong parentName="h4">{`JS API`}</strong>{`:`}</h4>
    <ul>
      <li parentName="ul">{`Added `}<a parentName="li" {...{
          "href": "/javascript-api/methods/#log"
        }}><inlineCode parentName="a">{`log`}</inlineCode></a>{` method`}</li>
    </ul>
    <h3 {...{
      "id": "v130-20210506"
    }}>{`v1.3.0 (`}<strong parentName="h3">{`2021.05.06`}</strong>{`)`}</h3>
    <h4 {...{
      "id": "js-api-1"
    }}><strong parentName="h4">{`JS API`}</strong>{`:`}</h4>
    <ul>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`baseURL`}</inlineCode>{` option for `}<a parentName="li" {...{
          "href": "/javascript-api/methods/#newpageview"
        }}><inlineCode parentName="a">{`newPageView`}</inlineCode></a>{` method.`}</li>
    </ul>
    <h3 {...{
      "id": "v12-20201209"
    }}>{`v1.2 (`}<strong parentName="h3">{`2020.12.09`}</strong>{`)`}</h3>
    <h4 {...{
      "id": "js-api-2"
    }}><strong parentName="h4">{`JS API`}</strong>{`:`}</h4>
    <ul>
      <li parentName="ul">{`Added `}<a parentName="li" {...{
          "href": "/javascript-api/methods/#track"
        }}><inlineCode parentName="a">{`track`}</inlineCode></a>{` method.`}</li>
    </ul>
    <h3 {...{
      "id": "v11-20190405"
    }}>{`v1.1 (`}<strong parentName="h3">{`2019.04.05`}</strong>{`)`}</h3>
    <h4 {...{
      "id": "js-api-3"
    }}><strong parentName="h4">{`JS API`}</strong>{`:`}</h4>
    <ul>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`conditions`}</inlineCode>{` to `}<a parentName="li" {...{
          "href": "/javascript-api/methods/#newpageview"
        }}><inlineCode parentName="a">{`newPageView`}</inlineCode></a>{` method.`}</li>
      <li parentName="ul">{`Added `}<a parentName="li" {...{
          "href": "/javascript-api/methods/#debug"
        }}><inlineCode parentName="a">{`debug`}</inlineCode></a>{` method.`}</li>
      <li parentName="ul">{`Added `}<inlineCode parentName="li">{`rootHostname`}</inlineCode>{` to `}<a parentName="li" {...{
          "href": "/javascript-api/configuration"
        }}><inlineCode parentName="a">{`options`}</inlineCode></a>{` to support tracking across subdomains.`}</li>
    </ul>
    <h3 {...{
      "id": "v10-20190101"
    }}>{`v1.0 (`}<strong parentName="h3">{`2019.01.01`}</strong>{`)`}</h3>
    <ul>
      <li parentName="ul">{`Base version of the API that existed before public documentation was introduced.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      